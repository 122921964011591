import { toast, TypeOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export enum ToastId {
  Default = 'default-toast-id',
  Information = 'information-toast-id',
  Warning = 'warning-toast-id',
  Error = 'error-toast-id',
  Success = 'success-toast-id',
  Outage = 'outage-toast-id',
}

export const showInformmation = (text: string) => toast(text, { toastId: ToastId.Information, type: 'info' })

export const showWarning = (text: string) => toast(text, { toastId: ToastId.Warning, type: 'warning' })

export const showError = (text: string) => toast(text, { toastId: ToastId.Error, type: 'error' })

export const showSuccess = (text: string) => toast(text, { toastId: ToastId.Success, type: 'success' })

export const updateToast = (text: string, type: TypeOptions, toastId: ToastId) =>
  toast.update(toastId, {
    render: text,
    type: type,
  })

export const showOutage = (text: string, type: TypeOptions) =>
  toast(text, {
    toastId: ToastId.Outage,
    type: type,
  })

export const dismiss = (toastId: ToastId) => toast.dismiss(toastId)
export const dismissAll = () => toast.dismiss()
