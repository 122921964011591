import React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from '../src/components/app/App'
import registerServiceWorker from './registerServiceWorker'
import ScrollToTop from './components/scrollToTop/ScrollToTop'

// The following imports have to be in this order for bootstrap to correctly work.
import './createBootstrapGlobals'
import 'bootstrap'

import './styling/main.css' // USE THIS AS DEFAULT.

// import './assets/css/462.min.css' // NOTE: Use only at development time!
// import './assets/css/618.min.css' // NOTE: Use only at development time!

import { initSentry } from './utils/sentry'
import MainBoundary from './components/app/MainBoundary'

import store from './utils/store'
import { ToastContainer, Zoom } from 'react-toastify'

// Add React performance devtool if environment is development
// To use react-perf-devtool, read guide here: https://github.com/nitin42/react-perf-devtool
// if (process.env.NODE_ENV === 'development') {
// tslint:disable-next-line:no-var-requires
// const registerObserver = require('react-perf-devtool')
// registerObserver()
// }

const app = (
  <>
    <ToastContainer
      draggable={true}
      closeButton={true}
      closeOnClick={true}
      pauseOnHover={true}
      pauseOnFocusLoss={false}
      hideProgressBar={false}
      position={'top-center'}
      autoClose={5000}
      newestOnTop
      theme="colored"
      transition={Zoom}
      //style={{ width: '24vw', letterSpacing: '0.15ch' }}
      style={{ width: '24vw', letterSpacing: '0.17ch', whiteSpace: 'pre-line' }}
    />
    <MainBoundary>
      <Provider store={store}>
        <Router>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Router>
      </Provider>
    </MainBoundary>
  </>
)

initSentry()

ReactDOM.render(app, document.getElementById('root') as HTMLElement)
registerServiceWorker()

export default store
